import React from "react";

import { useIntl } from "react-intl";

import { Link } from "react-router-dom";
import Page from "components/Page";
import PageTitle from "components/PageTitle";
import BranchLink from "components/BranchLink";
import Helmet from "components/Helmet";
import Carousel from "components/Carousel";
import FormattedMessage from "helpers/formattedMessage";
import { goalHomeDefaultClick } from "helpers/goalTracking";

import useCommunityPosts from "hooks/useCommunityPosts";
import imageLogoPeanut from "./assets/peanut-app-logo.svg";
import loadable from "@loadable/component";
import QRCodeCard from "components/QRCodeCard";

import imageLogoEvening from "./assets/logo-evening.webp";
import imageLogoForbes from "./assets/logo-forbes.webp";

import imageLogoRefinery29 from "./assets/logo-refinery29.webp";

import imageVerticalFertility from "./assets/verticals-fertility.webp";
import imageVerticalMenopause from "./assets/verticals-menopause.webp";
import imageVerticalMotherhood from "./assets/verticals-motherhood.webp";
import imageVerticalPregnancy from "./assets/verticals-pregnancy.webp";

import assetsEn from "./assets/en";
import assetsEs from "./assets/es";

import styles from "./Home.module.scss";
import { imageMapping } from "./imageMapping";
import Banner from "./Banner/Banner";

const CommunityPostCard: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-CommunityPostCard"              */ "../../components/CommunityPostCard"
    )
);

const CommunityPostCardSkeleton: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-CommunityPostCardSkeleton"              */ "../../components/CommunityPostCard/CommunityPostCard.skeleton"
    )
);

const GoogleAdPlacement: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-GoogleAdPlacement"              */ "../../components/GoogleAdPlacement"
    )
);

function Home() {
  const intl = useIntl();

  const assets = intl.locale === "es" ? assetsEs : assetsEn;

  const { data: posts, loading: postsLoading } = useCommunityPosts({
    limit: 3,
    locale: intl.locale,
  });

  const getLocalizedMom = () => {
    if (typeof navigator !== 'undefined') {
      return navigator.language === "en-GB" ? "mum" : "mom";
    }
    return "mom";
  };

  return (
    <Page className="page--home">
      <Helmet
        title={intl.formatMessage({
          id: "pages.home.meta_title",
          defaultMessage: "Peanut - Find Friends and Support",
          description: "Meta title for the home page",
        })}
        description={intl.formatMessage({
          id: "pages.home.meta_description",
          defaultMessage:
            "Whether you're navigating fertility, pregnancy, motherhood or menopause, Peanut is a safe space to ask questions, find support and connect with other women.",
          description: "Meta decription for the home page",
        })}
        hrefLang={[
          { locale: "es", url: `${process.env.REACT_APP_BASE_URL}/es` },
          {
            locale: "en",
            url: `${process.env.REACT_APP_BASE_URL}`,
            default: true,
          },
        ]}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Peanut",
            url: "https://www.peanut-app.io",
            logo: imageLogoPeanut,
            sameAs: [
              "https://twitter.com/peanut/",
              "https://www.facebook.com/PeanutAppOfficial/",
              "https://www.instagram.com/peanut/",
              "https://uk.pinterest.com/peanutapp/",
            ],
          })}
        </script>
      </Helmet>
      <section className="hero">
        <div className="hero-text-container">
          <PageTitle
            className="PageHome-title"
            title={
              <FormattedMessage
                id="pages.home.newhero_title"
                defaultMessage="Find {momOrMum} friends."
                description="Welcome header on app main page"
                values={{ momOrMum: getLocalizedMom(), linebreak: <br /> }}
              />
            }
          />

          <p className="hero-subtitle">
            <FormattedMessage
              defaultMessage="Join a community of women through every life stage: fertility, pregnancy, motherhood, menopause, and beyond."
              description="Subtitle for the hero section on the home page"
              id="pages.home.newhero_paragraph"
            />
          </p>

          <div className="homeQRCard">
            <QRCodeCard />
          </div>
          <div className="home-install">
            <BranchLink platform="default" onClick={goalHomeDefaultClick}>
              <FormattedMessage
                defaultMessage="Download the app"
                description="Label: Download Peanut"
                id="generic.actions.downloadpeanut"
              />
            </BranchLink>
          </div>
        </div>
        <div className="hero-image-container">
          <img
            className={styles.heroImage}
            sizes="
              (min-width: 1200px) 500w,
              (min-width: 700px)  466w,  
              100vw,"
            srcSet={Object.entries(assets.header)
              .map(([key, value]) => `${value} ${key}w`)
              .join(", ")}
            alt={intl.formatMessage({
              id: "generic.peanut_app",
              defaultMessage: "Peanut App",
              description: "Alt text for misc. screen shots",
            })}
          />
        </div>
      </section>
      <div>
        <GoogleAdPlacement position="content" className={styles.adContent} />
      </div>
      <section>
        <Banner
          id="pages.home.banner.quiz"
          defaultMessage="Peanut provides access to a community who are there to listen, share information and offer valuable advice."
          description="Link on react page"
        />
      </section>
      <section className="home-why-section">
        <p>
          <FormattedMessage
            id="pages.home.lifestage_intro"
            defaultMessage="Peanut provides access to a community who are there to listen, share
          information and offer valuable advice."
            description="Link on react page"
          />
        </p>

        <div className="verticals">
          <div className="vertical">
            <div className="vertical-icon">
              <Link
                to={intl.formatMessage({
                  id: "generic.url.fertilitycommunity",
                  defaultMessage: "fertility-community",
                  description:
                    "Navigation url: fertility-community (use - for spaces, no punctuation)",
                })}
              >
                <img
                  src={imageVerticalFertility}
                  alt={intl.formatMessage({
                    defaultMessage: "Fertility",
                    description: "Lifestage: Fertility",
                    id: "lifestage.fertility",
                  })}
                  className={styles.verticalImage}
                />
              </Link>
            </div>
            <FormattedMessage
              defaultMessage="Fertility"
              description="Lifestage: Fertility"
              id="lifestage.fertility"
            />
          </div>
          <div className="vertical">
            <div className="vertical-icon">
              <Link
                to={intl.formatMessage({
                  id: "generic.url.pregnancycommunity",
                  defaultMessage: "pregnancy-community",
                  description:
                    "Navigation url: pregnancy-community (use - for spaces, no punctuation)",
                })}
              >
                <img
                  src={imageVerticalPregnancy}
                  alt={intl.formatMessage({
                    defaultMessage: "Pregnancy",
                    description: "Lifestage: Pregnancy",
                    id: "lifestage.pregnancy",
                  })}
                  className={styles.verticalImage}
                />
              </Link>
            </div>
            <FormattedMessage
              defaultMessage="Pregnancy"
              description="Lifestage: Pregnancy"
              id="lifestage.pregnancy"
            />
          </div>
          <div className="vertical">
            <div className="vertical-icon">
              <Link
                to={intl.formatMessage({
                  id: "generic.url.motherhoodcommunity",
                  defaultMessage: "motherhood-community",
                  description:
                    "Navigation url: motherhood-community (use - for spaces, no punctuation)",
                })}
              >
                <img
                  src={imageVerticalMotherhood}
                  alt={intl.formatMessage({
                    defaultMessage: "Motherhood",
                    description: "Lifestage: Motherhood",
                    id: "lifestage.motherhood",
                  })}
                  className={styles.verticalImage}
                />
              </Link>
            </div>
            <FormattedMessage
              defaultMessage="Motherhood"
              description="Lifestage: Motherhood"
              id="lifestage.motherhood"
            />
          </div>
          <div className="vertical">
            <div className="vertical-icon">
              <Link
                to={intl.formatMessage({
                  id: "generic.url.menopausecommunity",
                  defaultMessage: "menopause-community",
                  description:
                    "Navigation url: menopause-community (use - for spaces, no punctuation)",
                })}
              >
                <img
                  src={imageVerticalMenopause}
                  alt={intl.formatMessage({
                    defaultMessage: "Menopause",
                    description: "Lifestage: Menopause",
                    id: "lifestage.menopause",
                  })}
                  className={styles.verticalImage}
                />
              </Link>
            </div>
            <FormattedMessage
              defaultMessage="Menopause"
              description="Lifestage: Menopause"
              id="lifestage.menopause"
            />
          </div>
        </div>
      </section>
      <section className={styles.communityWrapper}>
        <h2 className="section-title">
          <FormattedMessage
            defaultMessage="Community"
            description="Navigation: Community"
            id="generic.navigation.community"
          />
        </h2>
        <div>
          {postsLoading || !posts ? (
            <>
              <CommunityPostCardSkeleton />
              <CommunityPostCardSkeleton />
              <CommunityPostCardSkeleton />
            </>
          ) : (
            posts?.map((post) => (
              <CommunityPostCard key={post.uid} post={post} />
            ))
          )}
        </div>
      </section>
      <section className={styles.pressLogos}>
        {imageMapping.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className={styles[image.className]}
            loading="lazy"
          />
        ))}
      </section>
      <section className="hero-mission-section">
        <p>
          <FormattedMessage
            defaultMessage="We envision a world where no woman has to navigate life alone. Where they have a safe space to find friendship and be vulnerable."
            description="Homepage mission statement"
            id="pages.home.mission"
          />
        </p>
      </section>
      <section className="features">
        <section>
          <div className={styles.textWrapper}>
            <h2>
              <FormattedMessage
                defaultMessage="Swipe to Find Friends Nearby"
                description="Homepage features, discovery section title"
                id="pages.home.features.discovery"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="Discover and connect with like-minded women in your area who share your interests, hobbies, and life stages."
                description="Homepage features, discovery section description"
                id="pages.home.features.discovery_description"
              />
            </p>
          </div>

          <img
            className={styles.featureImage}
            sizes="
                    (min-width: 700px)  440w,  
                    100vw,"
            srcSet={Object.entries(assets.featuresGroup)
              .map(([key, value]) => `${value} ${key}w`)
              .join(", ")}
            alt={intl.formatMessage({
              defaultMessage: "Swipe to Find Friends Nearby",
              description: "Homepage features, discovery section title",
              id: "pages.home.features.discovery_title",
            })}
            loading="lazy"
          />
        </section>

        <section>
          <div className={styles.textWrapper}>
            <h2>
              <FormattedMessage
                defaultMessage="Join Groups and Communities"
                description="Homepage features, groups section title"
                id="pages.home.features.group_title"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="Explore groups and communities that align with your journey, from local meet-ups to shared interests."
                description="Homepage features, groups section description"
                id="pages.home.features.group_description"
              />
            </p>
          </div>

          <img
            className={styles.featureGroups}
            sizes="
                    (min-width: 700px)  440w,  
                    100vw,"
            srcSet={Object.entries(assets.featuresShare)
              .map(([key, value]) => `${value} ${key}w`)
              .join(", ")}
            alt={intl.formatMessage({
              defaultMessage: "Join Groups and Communities",
              description: "Homepage features, groups section title",
              id: "pages.home.features.group_title",
            })}
            loading="lazy"
          />
        </section>

        <section>
          <div className={styles.textWrapper}>
            <h2>
              <FormattedMessage
                defaultMessage="Ask Questions and Get Advice"
                description="Homepage features, community section title"
                id="pages.home.features.community_title"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="Whether you’re looking for guidance of have stories to share, Peanut provides valuable advice from real women."
                description="Homepage features, community section description"
                id="pages.home.features.community_description"
              />
            </p>
          </div>
          <img
            className={styles.featureGroups}
            sizes="
                    (min-width: 700px)  440w,  
                    100vw,"
            srcSet={Object.entries(assets.featuresMeet)
              .map(([key, value]) => `${value} ${key}w`)
              .join(", ")}
            alt={intl.formatMessage({
              defaultMessage: "Ask Questions and Get Advice",
              description: "Homepage features, community section title",
              id: "pages.home.features.community_title",
            })}
            loading="lazy"
          />
        </section>
      </section>
      <section className="people-quotes">
        <Carousel interval={5000}>
          <blockquote>
            <p>
              <FormattedMessage
                defaultMessage="Peanut means a network, it means meeting new women who are going
              through your same experiences, it means supporting one another
              through this wonderful adventure!"
                description="Homepage Quote 1"
                id="pages.home.quote1"
              />
            </p>
            <cite>
              <FormattedMessage
                defaultMessage="Alice P."
                description="Homepage Quote 1 author"
                id="pages.home.quote1_author"
              />
            </cite>
          </blockquote>

          <blockquote>
            <p>
              <FormattedMessage
                defaultMessage="It's so easy to navigate and before I knew it, I was
              messaging one of my neighbors and planning a big meetup."
                description="Homepage Quote 2"
                id="pages.home.quote2"
              />
            </p>
            <cite>
              <FormattedMessage
                defaultMessage="Emily M."
                description="Homepage Quote 2 author"
                id="pages.home.quote2_author"
              />
            </cite>
          </blockquote>

          <blockquote>
            <p>
              <FormattedMessage
                defaultMessage="I love the Peanut app! I met my best friend who lives directly
              across the street. I would never have met her if it weren't
              for this app!"
                description="Homepage Quote 3"
                id="pages.home.quote3"
              />
            </p>
            <cite>
              <FormattedMessage
                defaultMessage="Kristen P."
                description="Homepage Quote 3 author"
                id="pages.home.quote3_author"
              />
            </cite>
          </blockquote>

          <blockquote>
            <p>
              <FormattedMessage
                defaultMessage=" I wish I knew about Peanut sooner, as it's so important to
              surround yourself with women who understand during those
              overwhelming moments of womanhood."
                description="Homepage Quote 4"
                id="pages.home.quote4"
              />
            </p>
            <cite>
              <FormattedMessage
                defaultMessage="Lydia M."
                description="Homepage Quote 4 author"
                id="pages.home.quote4_author"
              />
            </cite>
          </blockquote>

          <blockquote>
            <p>
              <FormattedMessage
                defaultMessage="I mean, seriously, genius, and so fun! I've been using the
              app for a few weeks and have already been able to connect with
              several awesome women that are within a local distance from me."
                description="Homepage Quote 5"
                id="pages.home.quote5"
              />
            </p>
            <cite>
              <FormattedMessage
                defaultMessage="Madison H."
                description="Homepage Quote 5 author"
                id="pages.home.quote5_author"
              />
            </cite>
          </blockquote>
        </Carousel>
      </section>
      <section className="press-quotes">
        <blockquote>
          <p>
            <FormattedMessage
              defaultMessage="While a number of online groups already exist–Peanut is far more
            customized."
              description="Homepage press quote 1 (Forbes)"
              id="pages.home.press_quote1"
            />
          </p>
          <cite>
            <img
              src={imageLogoForbes}
              className={styles.pressQuoteForbes}
              alt="Forbes"
            />
          </cite>
        </blockquote>

        <blockquote>
          <p>
            <FormattedMessage
              defaultMessage="Peanut is a welcome sign that change is afoot, and the internet is
            finally growing up."
              description="Homepage press quote 2 (Refinery 29)"
              id="pages.home.press_quote2"
            />
          </p>
          <cite>
            <img
              src={imageLogoRefinery29}
              className={styles.pressQuoteRefinery29}
              alt="Refinery 29"
            />
          </cite>
        </blockquote>

        <blockquote>
          <p>
            <FormattedMessage
              defaultMessage="A space where women can seek advice and support from those on
            similar journeys."
              description="Homepage press quote 3 (Evening Standard)"
              id="pages.home.press_quote3"
            />
          </p>
          <cite>
            <img
              src={imageLogoEvening}
              className={styles.pressQuoteEvening}
              alt="Evening Standard"
            />
          </cite>
        </blockquote>
      </section>
    </Page>
  );
}

export default Home;
